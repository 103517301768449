import * as types from './mutation_types'

export default {
  [types.CLICK_CREATE_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.CLICK_CREATE_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.CLICK_CREATE_SET_CREATED] (state, created) {
    Object.assign(state, { created })
  },

}
