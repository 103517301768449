import HomeList from '../components/home/List'
import View from '../components/viewer25d/View';
import Contact from '../components/home/Contact';
import IntroMovie  from '../components/home/IntroMovie';
import Start from '../components/home/Start';

export default [
  { name: 'Start', path: '/', component: Start, meta: { navHidden: true, requiresAuth: false } },
  { name: 'HomeList', path: '/feed', components: {'HomeTab':HomeList}, meta: { requiresAuth: false } },
  { name: 'Contact', path: '/contact', components: {default: Contact}, meta: {navHidden: true,  requiresAuth: false } },
  { name: 'Viewer25D', path: '/viewer25d/:id', components: { default: View}, meta: { navHidden: true, requiresAuth: false } },
  { name: 'IntroMovie', path: '/intromovie', components: { default: IntroMovie}, meta: { navHidden: true, requiresAuth: false } },
]
