import Vue from 'vue'
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import App from './App.vue';
import './registerServiceWorker'
// Add the modules in the store
import home from './store/modules/home/';
import map from './store/modules/map/';
import comment from './store/modules/comment/';
import imagegallery from './store/modules/imagegallery/';
import organization from './store/modules/organization/';
import person from './store/modules/person/';
import photograph from './store/modules/photograph/';
import retrosnap from './store/modules/retrosnap/';
import category from './store/modules/category/';
import photographcollection from './store/modules/photographcollection/';
import svgtemplate from './store/modules/svgtemplate/';
import place from './store/modules/place/';
import postaladdress from './store/modules/postaladdress/';
import feeditem from './store/modules/feeditem/';
import sponsor from './store/modules/sponsor/';
import user from './store/modules/user/';
import searchfilters from './store/modules/searchfilters/';
import settings from './store/modules/settings/';
import product from './store/modules/product/';
import order from './store/modules/order/';
import mention from './store/modules/mention/';
import announcement from './store/modules/announcement/';
//import ranking from './store/modules/ranking/';
//import NotFound from './components/home/NotFound';

//import VueGtag from "vue-gtag";


import { routes } from './router/routes';
import vuetify from './plugins/vuetify';
import i18n from './i18n'

Vue.use(Vuex);
Vue.use(VueRouter);

import { createVPhoneInput } from 'v-phone-input';
//import 'v-phone-input/dist/v-phone-input.css';
require('v-phone-input/dist/v-phone-input.css')
import 'flag-icons/css/flag-icons.min.css';

const vPhoneInput = createVPhoneInput({
  countryIconMode: 'svg',
});

Vue.use(vPhoneInput);

import VueZoomer from 'vue-zoomer'
Vue.use(VueZoomer)

import 'mapbox-gl/dist/mapbox-gl.css'
import 'v-mapbox/dist/v-mapbox.css';
//import Mapbox from "mapbox-gl"
//import { VMap } from "v-mapbox"

//Vue.use(VMap, { mapboxgl: Mapbox });


import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

//import VueMasonry from 'vue-masonry-css'
//Vue.use(VueMasonry);

import VueOffline from 'vue-offline'
Vue.use(VueOffline)

import FULLTILT from './components/viewer25d/utils/fulltilt';
window.FULLTILT=FULLTILT();


//import Gravatar from 'vue-gravatar'
import Webpush from "./utils/webpush-client";
import {ENTRYPOINT} from "./config/entrypoint";
//Vue.component('v-gravatar', Gravatar)

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

//import VueCompositionAPI from '@vue/composition-api';
//Vue.use(VueCompositionAPI);
const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    home,
    map,
    comment,
    imagegallery,
    organization,
    person,
    photograph,
    retrosnap,
    category,
    photographcollection,
    svgtemplate,
    place,
    postaladdress,
    feeditem,
    sponsor,
    user,
   // ranking,
    searchfilters,
    mention,
    settings,
    product,
    order,
    announcement
  }
});

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  /*scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 200 }
    }
  }*/

});

window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }


const waitForStorageToBeReady = async (to, from, next) => {
  window.console.everything = [];
  await store.restored
  next()
}
router.beforeEach(waitForStorageToBeReady)
/*router.afterEach(()=>{


})*/

/*router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = !!TokenService.getToken();

  if (!loggedIn && to!=='/login') {
    return next({
      path:'/login',
      query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
    });
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/')
  }

  next();
})
*/
/*
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG_ID },
  //disableScriptLoad: true,
  bootstrap: false,
  globalObjectName: 'gtag'
}, router);
*/

//script.hash.outbound-links
/*import { VuePlausible } from 'vue-plausible'
//if (process.env.NODE_ENV === 'production') {
  Vue.use(VuePlausible, {
    domain: 'nimmerso.app',
    hasMode: true,
    trackLocalhost: true,
    enableAutoPageviews: true,
    enableAutoOutboundTracking: true,
  })
  window.addEventListener('appinstalled', () => {
    if(typeof Vue.$plausible !== 'undefined'){
      Vue.$plausible.trackEvent('appinstalled');
    }
  });
//}
//Vue.$plausible.enableAutoPageviews(); // optional
//Vue.$plausible.enableAutoOutboundTracking();
*/
Webpush.create({
  serviceWorkerPath: '/service-worker.js', // Public path to the service worker
  serverKey: 'BK_TzXFYmiRL3cJGsX622Dhw9z7238ZiG-G5CIRcr5GaSnsfN-xtuxTVshCyGHZ7nlIzPrT_erYvcON5ankJUtk', // https://developers.google.com/web/fundamentals/push-notifications/web-push-protocol#application_server_keys
  subscribeUrl: ENTRYPOINT + '/webpush', // Optionnal - your application URL to store webpush subscriptions
})
  .then(WebPushClient => {
    window.window.console.log(WebPushClient.isSupported(), WebPushClient.getPermissionState());
    // do stuff with WebPushClient
    window.webPushClient=WebPushClient;
    //this.webPushClient.subscribe([])
  })
;

import { NimmersoOfflineStorage} from './utils/nimmerso-offline-storage'
NimmersoOfflineStorage.clearExpired();


if (window.console.everything === undefined)
{
  window.console.everything = [];

  window.console.defaultLog = window.console.log.bind(console);
  window.console.log = function(){
    window.console.everything.push({"type":"log", "datetime":Date().toLocaleString(), "location":window.location.href, "value":Array.from(arguments)});
    window.console.defaultLog.apply(console, arguments);
  }
  window.console.defaultError = window.console.error.bind(console);
  window.console.error = function(){
    window.console.everything.push({"type":"error", "datetime":Date().toLocaleString(), "location":window.location.href, "value":Array.from(arguments)});
    window.console.defaultError.apply(console, arguments);
  }
  window.console.defaultWarn = window.console.warn.bind(console);
  window.console.warn = function(){
    //window.console.everything.push({"type":"warn", "datetime":Date().toLocaleString(), "location":window.location.href, "value":Array.from(arguments)});
    window.console.defaultWarn.apply(console, arguments);
  }
  window.console.defaultDebug = window.console.debug.bind(console);
  window.console.debug = function(){
    //window.console.everything.push({"type":"debug", "datetime":Date().toLocaleString(), "location":window.location.href, "value":Array.from(arguments)});
    window.console.defaultDebug.apply(console, arguments);
  }
}


new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
